import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";

const AddEditAchievementModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {

    if (editData?._id) {
      setName(editData.name);
      setDescription(editData.description)
    }
  }, [editData]);


  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`achievement/${editData?._id ? "edit" : "add"
      }`,
      {
        id: editData?._id || null,
        name: name,
        description
      },editData?._id ?"PUT":"POST"
    );

    if (res.status === 200 || res.status === 201) {
      setName("");
      setDescription("")
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="relative w-full">
        <div className="mb-4">
          <Input
            label={"Achievements Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Achievements Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"Achievements Description"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Achievements"}
            inputValue={description}
            setInput={setDescription}

          />
        </div>


      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAchievementModal;
