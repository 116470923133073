import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditCategoryModal from "../components/modals/AddEditCategoryModal";
import { useParams } from "react-router-dom";

const Categories = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const params = useParams();

  useEffect(() => {
    if (user?.email) {
      const loadList = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }
        const res = await postData(
          `categories?keyword=${keyword}&type=1&limit=${limit}&offset=${offset}`,
          {},
          "GET"
        );

        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.docs,
            loading: false,
            count: res.totalDocs,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadList();
    }
  }, [user, keyword, limit, offset, sortField, sortType, params]);

  const onDrag = async (data = []) => {
    const res = await postData(
      "crud/ordering",
      {
        table_name: "category",
        ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
      },
      "POST"
    );

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };
  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "Created At",
      name: "createdAt",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData(
      "categories/status-change",
      {
        ids: [id],
        status: list.data.find((item) => item._id === id).status === 1 ? 2 : 1,
      },
      "PATCH"
    );

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status:
                  list.data.find((item) => item._id === id).status === 1
                    ? 2
                    : 1,
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status === 1 ? "Inactive" : "Active",
      icon: item.status === 1 ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `categories/${item._id}`,
      standout: true,
    },
  ];

  return (
    <>
      <ListLayout
        title={"Themes"}
        addName={"Add Theme"}
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>
      <AddEditCategoryModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit Theme" : "Add Theme"}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
        urlParams={params}
      />
    </>
  );
};

export default Categories;
