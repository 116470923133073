import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditRecipeModal from "../components/modals/AddEditRecipeModal";
import SearchCategories from "../components/elements/search/SearchCategories";
import MultipalSearch from "../components/elements/search/MultipalSearch";
const Recipe = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", isChecked: true },
    { _id: 2, name: "Inactive", type: "checkbox", isChecked: false },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchNutrition, setSearchNutrition] = useState([]);
  const [searchIngredient, setSearchIngredient] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [nutritionId, setNutritionId] = useState([]);
  const [ingredientId, setIngredientId] = useState([]);
  const [searchSubCategory, setSearchSubCategory] = useState([]);
  const [subCategoriesId, setSubCategoriesId] = useState([]);

  useEffect(() => {
    const searchCategory = async () => {
      const res = await postData(
        `categories?type=main&for=recipe&limit=${9999}&offset=${0}`,{},"GET"
      );

      if (res.status === 200) {
        const obj = [];
        obj[0] = {
          _id: "",
          label: "Categories",
          value: "",
        };
        res.data.list.forEach((ele, i) => {
          obj[i + 1] = {
            _id: ele._id,
            label: ele.name,
          };
        });
        setSearchCategory(obj);
      }
    };

    const searchSubCategory = async () => {
      const res = await postData(`categories?type=sub&for=recipe&status=${true}`, {
      });

      if (res.status === 200) {
        const obj = [];

        res.data.list.forEach((ele, i) => {
          obj[i] = {
            _id: ele._id,
            label: ele.name,
          };
        });
        setSearchSubCategory(obj);
      }
    };

    const searchIngredient = async () => {
      const res = await postData(`ingredient/list?status=${true}`, {
      });

      if (res.status === 200) {
        const obj = [];

        res.data.list.forEach((ele, i) => {
          obj[i] = {
            _id: ele._id,
            label: ele.title,
          };
        });

        setSearchIngredient(obj);
      }
    };

    const searchNutrition = async () => {
      const res = await postData(`nutrition/list?status=${true}`, {
        status: true,
      });

      if (res.status === 200) {
        const obj = [];
        res.data.list.forEach((ele, i) => {
          obj[i] = {
            _id: ele._id,
            label: ele.title,
          };
        });
        setSearchNutrition(obj);
      }
    };

    searchNutrition();
    searchIngredient();
    searchCategory();
    searchSubCategory();
  }, []);

  useEffect(() => {
    if (user?.email) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.title = { $regex: keyword, $options: "i" };
        }

        const res = await postData(
          `recipe/list?keyword=${keyword}&keyword_key=title&status=${
            statusFilterData[0].isChecked === true &&
            statusFilterData[1].isChecked === true
              ? ""
              : statusFilterData[0].isChecked === true
              ? true
              : statusFilterData[1].isChecked === true
              ? false
              : ""
          }&limit=${limit}&offset=${offset}&category_id=${categoryId}&nutrition_ids=${nutritionId}&ingredient_ids=${ingredientId}&sub_categories=${subCategoriesId}&for=recipe&dateRange[start]=${
            dateRange[0] ? dateRange[0] : ""
          }&dateRange[end]=${
            dateRange[1] ? dateRange[1] : ""
          }&limit=${limit}&offset=${offset}`,
          {},
          "GET"
        );
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [
    user,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange,
    statusFilterData,
    categoryId,
    nutritionId,
    ingredientId,
    subCategoriesId,
  ]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Image",
      name: "image",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      _id: 2,
      width: 35,
      title: "Name",
      name: "title",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Category",
      name: "category",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange(e);
        }
      },
    },

    {
      _id: 5,
      width: 10,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                  ...item,
                  isChecked: !item.isChecked,
                }
          )
        );
      },
    },
    {
      _id: 6,
      width: 10,
      title: "",
      name: "",
      align: "right",
      sortable: false,
      filterable: false,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData(
      "crud/statusChange",
      {
        table_name: "recipe",
        id: [id],
        column_name: "status",
        column_value: !(
          list.data.find((item) => item._id === id).status || false
        ),
      },
      "POST"
    );

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `/recipe/delete/${item._id}`,
      deletePayload: { id: item._id },
      standout:true
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData(
      "crud/ordering",
      {
        table_name: "recipe",
        ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
      },
      "POST"
    );

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };

  return (
    <>
      <ListLayout
        title="Recipes"
        addName="Add Recipe"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        searchCategory={
          <SearchCategories
            data={searchCategory}
            handleSelect={(id) => {
              setCategoryId(id);
            }}
          />
        }
        searchNutrition={
          <MultipalSearch
            data={searchNutrition}
            label={"Nutritions"}
            updateHandler={(id) => {
              if (id) {
                setNutritionId((ids) => {
                  if (!id) {
                    return ids;
                  }
                  if (ids.includes(id)) {
                    return ids.filter((ele) => ele !== id);
                  } else {
                    return [...ids, id];
                  }
                });
              } else {
                setNutritionId([]);
              }
            }}
          />
        }
        searchIngredient={
          <MultipalSearch
            data={searchIngredient}
            label={"Ingredients"}
            updateHandler={(id) => {
              if (id) {
                setIngredientId((ids) => {
                  if (!id) {
                    return ids;
                  }
                  if (ids.includes(id)) {
                    return ids.filter((ele) => ele !== id);
                  } else {
                    return [...ids, id];
                  }
                });
              } else {
                setIngredientId([]);
              }
            }}
          />
        }
        searchSubctegory={
          <MultipalSearch
            data={searchSubCategory}
            label={"Subcategories"}
            updateHandler={(id) => {
              if (id) {
                setSubCategoriesId((ids) => {
                  if (!id) {
                    return ids;
                  }
                  if (ids.includes(id)) {
                    return ids.filter((ele) => ele !== id);
                  } else {
                    return [...ids, id];
                  }
                });
              } else {
                setSubCategoriesId([]);
              }
            }}
          />
        }
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>
      <AddEditRecipeModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit Recipe" : "Add Recipe"}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Recipe;
