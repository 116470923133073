import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
import toastr from 'toastr';
import AddEditAdminModal from '../components/modals/AddEditAdminModal';


const Admins = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null)
  const [sortType, setSortType] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  useEffect(() => {
    if (user?.email) {
      const loadList = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: 'i' }
        }
        const res = await postData(`users?keyword=${keyword}&type=admin&limit=${limit}&offset=${offset}&role=6399c15a302d0b2a866e0a59`, {
        },"GET");
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.docs,
            loading: false,
            count: res.totalDocs,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadList();
    }
  }, [user?.email, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Image",
      name: "image",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    
    {
      _id: 2,
      width: 15,
      title: 'Email',
      name: 'email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: 'Created At',
      name: 'createdAt',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    { 
       _id: 5,
       title:"Action",
       name:"action",
       action: true,
    }
  ];



  const changeStatus = async id => {
    const res = await postData('crud/statusChange', {
      table_name: 'user',
      id: [id],
      column_name: 'status',
      column_value: ! list.data.find((item) => item._id === id).status || false
  },"POST")



    if (res.status === 200) {
      setList(list => ({
        ...list,
        data: list.data.map(item => item._id !== id ? item : {
          ...item,
          status: !(list.data.find(item => item._id === id).status || false)
        })
      }))
    } else {
      toastr.error(res.message)
    }
  }

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(list.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: `users/${item._id}`,
      standout:true
    }
  ]

  return (
    <>
      <ListLayout
        title='Admins'
        addName='Add Admin'
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
        />
      </ListLayout>

      <AddEditAdminModal
       show={showModal}
       setShow={setShowModal}
       title={Object.keys(editData).length > 0 ? 'Edit Admin' : 'Add Admin'}
       setList={setList}
       editData={editData}
       setEditData={setEditData}
      />
    
    </>
  );
};

export default Admins;
