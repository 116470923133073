import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
import toastr from 'toastr';
import AddEditIngredientModal from '../components/modals/AddEditIngredientModal';

const Ingredients = () => {
  const user = useSelector((state) => state.auth.user);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [ingredients, setIngredients] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})
  useEffect(() => {
    if (user?.email) {
      const loadData = async () => {
        setIngredients((cats) => ({ ...cats, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.title = { $regex: keyword, $options: 'i' }
        }

        const res = await postData(`ingredient/list?keyword=${keyword}&keyword_key=title&limit=${limit}&offset=${offset}`, {
        });
        if (res.status === 200) {
          setIngredients((cats) => ({
            ...cats,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setIngredients((cats) => ({ ...cats, loading: false }));
        }
      };

      loadData();
    }
  }, [user, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'title',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'ingredient',
      id: [id],
      column_name: 'status',
      column_value: !(
        ingredients.data.find((item) => item._id === id).status || false
      ),
    },"POST");

    if (res.status === 200) {
      setIngredients((ingredients) => ({
        ...ingredients,
        data: ingredients.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                ingredients.data.find((item) => item._id === id).status ||
                false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(ingredients.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: `ingredient/delete/${item._id}`,
      standout:true
    },
  ];
  const onDrag = async (data = []) => {
    const res = await postData("crud/ordering", {
      table_name: "ingredient",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    },"POST");

    if (res.status !== 200) {
      toastr.error(res.message)
    }
  }

  return (
    <>
      <ListLayout
        title='Ingredients'
        addName='Add Ingredient'
        onAdd={() => setShowModal(true)}
        limit={limit}
        keyword={keyword}
        setLimit={setLimit}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(ingredients.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
        totalCount={ingredients.count}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={ingredients.data}
          loading={ingredients.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setIngredients}
          isDraggable={true}
          onDrag={onDrag}
       
        />
      </ListLayout>
      <AddEditIngredientModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Ingredient' : 'Add Ingredient'}
        setList={setIngredients}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Ingredients;
