import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { fileUpload, postData } from "../../services/api";
import toastr from "toastr";
import DropdownCheckbox from "../form/DropdownCheckbox";
import ButtonFile from "../form/ButtonFile";
import Button from "../form/Button";
import Image from "../elements/Image";
import { useLocation } from "react-router-dom";

const AddEditUserModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [designation,setDesignation]=useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [image,setImage]=useState("");
  const [password, setPassword] = useState("");
  const [appliance, setAppliance] = useState([]);
  const [nutritions, setNutritions] = useState([]);
const location = useLocation();

  useEffect(() => {
    if (editData?._id) {
      setName(editData?.name);
      setDesignation(editData?.designation);
      setEmail(editData?.email);
      setImage(editData?.image)
      setPassword(editData?.password);
      setPhone(editData?.phone);
    }
  }, [editData]);

  const loadApplianceList = async () => {
    const res = await postData(
      `appliance/list?status=${true}`,
      {
        status: true,
      },
      "GET"
    );
    if (res.status === 200 ) {
      let appliancesList = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((it, i) => {
          appliancesList.push({
            label: it.title,
            _id: it._id,
            isEnabled: false,
          });
        });

      setAppliance(appliancesList);
    }
  };

  const details = async () => {
    const userDetails = await postData(
      `user/details?id=${editData?._id}&type=user`,
      {},
      "GET"
    );

    if (userDetails.status === 200) {
      setNutritions(
        Array.isArray(nutritions) &&
          nutritions.map((it) => ({
            ...it,
            isEnabled:
              userDetails.data &&
              Array.isArray(userDetails.data.nutritions) &&
              userDetails.data.nutritions
                .map((item) => item._id)
                .includes(it._id),
          }))
      );

      setAppliance(
        Array.isArray(appliance) &&
          appliance.map((it) => ({
            ...it,
            isEnabled:
              userDetails.data &&
              Array.isArray(userDetails.data.appliances) &&
              userDetails.data.appliances
                .map((item) => item._id)
                .includes(it._id),
          }))
      );
    }
  };

  const loadNutritionList = async () => {
    const res = await postData(`nutrition/list?status=${true}`, {});
    if (res.status === 200) {
      let nutris = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((it, i) => {
          nutris.push({ label: it.title, _id: it._id, isEnabled: false });
        });

      setNutritions(nutris);
    }
  };

  useEffect(() => {
    //loadApplianceList();
    //loadNutritionList();
  }, []);
  useEffect(() => {
    if(show){
      details();
    }
 
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDisabled(false);
    setEditData({});
    setEmail("")
    setPhone("")
    setImage("")
    loadNutritionList();
    loadApplianceList();
  };
  const onSubmit = async () => {
    setDisabled(true);
      let url = `users`;
      if(editData?._id){
        url = `users/`+editData?._id;
      }

    let formData = new FormData();
    formData.append('id',editData?._id || null)
    formData.append('name',name)
    formData.append('designation',designation)
    formData.append('email',email)
    formData.append('password',password)
    formData.append('phone',phone)
    formData.append('type',location.pathname === '/chefs'?'chef':'user')
    formData.append('image',image)
    const res = await postData(
      url,
      formData,
      editData?._id ? "PUT" : "POST"
    );

    if (res.status === 201 || res.status === 200) {
      setName("");
      setEmail("");
      setPassword("");
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };
   
  const onImageChange = async (e) => {
    
    setImage(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    //console.log(e.target.files[0],'gile')
  
    // const imgRes = await fileUpload("file-upload", formData, "POST");
    // if (imgRes.status === 200) {
    //   setImage(imgRes?.location);
    //   setDisabled(false);
    // } else {
    //   setDisabled(false);
    // }
  };

  const updateHandler = async (id, checked) => {
    setAppliance(
      appliance.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
    setNutritions(
      nutritions.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
  };

  return (
    <AddEditLayoutModal
      title={`Add ${location.pathname === '/chefs'?'Chef':'User'}`}
      modalSize={"xl"}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <div className="mb-4">
          <Input
            label={" Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>

        <div className="mb-4">
          <Input
            label={"Designation"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Designation"}
            inputValue={designation}
            setInput={setDesignation}
          />
        </div>


        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <Input
              label={"User Email"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Email"}
              inputValue={email}
              setInput={setEmail}
            />
          </div>
          <div className="mb-4">
            <Input
              label={"User Phone"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Enter Phone"}
              inputValue={phone}
              setInput={setPhone}
            />
          </div>
        </div>
        {/* <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <div className="text-xs font-medium text-slate-500 mb-1">
              Select Appliances
            </div>
            <div className="relative w-full">
              <DropdownCheckbox
                data={appliance}
                appliances={appliance}
                functions={(id, event) => updateHandler(id, event)}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-xs font-medium text-slate-500 mb-1">
              Select Nutrition
            </div>
            <div className="relative w-full">
              <DropdownCheckbox
                data={nutritions}
                nutris={nutritions}
                functions={(id, event) => updateHandler(id, event)}
              />
            </div>
          </div>
        </div> */}
        <div className="mb-4">
          <Input
            label={"Password"}
            labelClasses={"!text-xs"}
            inputType={"password"}
            inputPlaceholder={"Enter Password"}
            inputValue={password}
            setInput={setPassword}
          />
        </div>
        <div className="mb-4">
          <ButtonFile
            functions={onImageChange}
            buttonLabel="Upload Image"
            buttonIconPosition=""
            buttonClasses={"!bg-white !text-slate-400 border border-slate-200"}
          />
        </div>
        {image ? (
          <>
            {image !== null && image instanceof File ? (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={URL.createObjectURL(image)}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            ) : (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden">
                <Button
                  buttonClasses={
                    "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                  }
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={image}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditUserModal;
