import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditClassModal from "../components/modals/AddEditClassModal";
import MultipalSearch from "../components/elements/search/MultipalSearch";

const Classes = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchRecipe, setSearchRecipe] = useState([]);
  const [categoriesId, setCategoriesId] = useState("");

  const recipeList = async () => {
    const res = await postData(`recipe/list?status=${true}`, {}, "GET");

    if (res.status === 200) {
      const obj = [];
      res.data.list.forEach((ele, i) => {
        obj[i] = {
          _id: ele._id,
          label: ele.title,
        };
      });
      setSearchRecipe(obj);
    }
  };

  useEffect(() => {
    recipeList();
  }, []);

  useEffect(() => {
    const loadList = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.name = { $regex: keyword, $options: "i" };
      }
      const res = await postData(
        `class/list?keyword=${keyword}&keyword_key=name&recipe_ids=${categoriesId}&limit=${limit}&offset=${offset}`,
        {},
        "GET"
      );
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadList();
  }, [user, keyword, limit, offset, sortField, sortType, categoriesId]);

  const statusChange = async (id) => {
    const res = await postData(
      "crud/statusChange",
      {
        table_name: "class",
        id: [id],
        column_name: "status",
        column_value:
          !list.data.find((item) => item._id === id).status || false,
      },
      "POST"
    );

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 20,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 50,
      title: "Description",
      name: "description",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    }
  ];

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: statusChange,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `class/delete/${item._id}`,
      standout:true
    },
  ];

  return (
    <>
      <ListLayout
        title="Classes"
        addName="Add Class"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
        disableButton={false}
        searchCategory={
          <MultipalSearch
            data={searchRecipe}
            label={"Recipes"}
            updateHandler={(id) => {
              if (id) {
                setCategoriesId((ids) => {
                  if (!id) {
                    return ids;
                  }
                  if (ids.includes(id)) {
                    return ids.filter((ele) => ele !== id);
                  } else {
                    return [...ids, id];
                  }
                });
              } else {
                setCategoriesId([]);
              }
            }}
          />
        }
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasActions={true}
        />
      </ListLayout>
      <AddEditClassModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit Class" : "Add Class"}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Classes;
