import { useState, useEffect, useRef } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import { fileUpload, postData } from "../../services/api";
import { tinymceApiKey } from "../../helpers";
import toastr from "toastr";
import Select from "../form/Select";
import DropdownCheckbox from "../form/DropdownCheckbox";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../form/Button";

const AddEditRecipeModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const [loader, setLoader] = useState(false);
  const [catid, setCatid] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [servings, setServings] = useState("");
  const [note, setNote] = useState("");
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [ingredients, setIngredient] = useState([]);
  const [nutritions, setNutrition] = useState("");
  const [subcategories, setSubCategories] = useState([]);
  const [cooktime, setCooktime] = useState("");
  const [preptime, setPreptime] = useState("");
  const [fromIngredient, setIngredientsValues] = useState([
    {
      ingredient: "",
      qty: "",
    },
  ]);

  const [formNutrition, setNutritionValues] = useState([
    {
      nutrition: "",
      qty: "",
    },
  ]);
  const [totalTime, setTotaltime] = useState("");
  const descriptionRef = useRef(null);
  const noteRef = useRef(null);
  const nutritionSelect = (i, e) => {
    let newFormValues = [...formNutrition];
    newFormValues[i]["nutrition"] = e;
    setNutritionValues(newFormValues);
  };

  const IngredientSelect = (i, e) => {
    let newFormValues = [...fromIngredient];
    newFormValues[i]["ingredient"] = e;
    setIngredientsValues(newFormValues);
  };

  useEffect(() => {
    if (editData?._id) {
      const loaddata = async () => {
        const res = await postData(
          `recipe/details?id=${editData?._id}`,
          {},
          "GET"
        );

        if (res.status === 200) {
          setName(res.data.title);
          setDescription(res.data.description);
          setServings(res.data.servings);
          setNote(res.data.note);
          setCatid(res.data.category_id);
          setIngredientsValues(res.data.ingredients);
          setNutritionValues(res.data.nutritions);
          setCooktime(res.data.cook_time);
          setPreptime(res.data.prep_time);
          setTotaltime(res.data.total_time);
          setImage(res.data.image);
        }
      };
      loaddata();
    }
  }, [editData._id]);

  useEffect(() => {
    const loaddata = async () => {
      const res = await postData(
        `recipe/details?id=${editData?._id}`,
        {},
        "GET"
      );
      if (res.status === 200) {
        if (catid !== 0) {
          postData(
            `categories?type=sub&for=recipe&parent_id=${catid}&status=${true}&limit=${1000}&offset=${0}`,
            {},
            "GET"
          ).then((response) => {
            if (response.status === 200) {
              let a = [];
              response.data.list.forEach((ele) => {
                a.push({
                  _id: ele._id,
                  label: ele.name,
                  isEnabled: false,
                });
              });

              a.forEach((ele) => {
                res.data.sub_category_id.forEach((ele2) => {
                  if (ele._id === ele2._id) {
                    ele.isEnabled = true;
                  }
                });
              });

              setSubCategories(a);
            }
          });
        }
      }
    };

    loaddata();
  }, [catid]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("");
    setImage(null);
    setNote("");
    setServings("");
    setEditData({});
    setNutritionValues([
      {
        nutrition: "",
        qty: "",
      },
    ]);
    setIngredientsValues([
      {
        ingredient: "",
        qty: "",
      },
    ]);
    setTotaltime("");
    setPreptime("");
    setCooktime("");
    setCatid(0);
    setLoader(false);

    const loadSubCategories = async () => {
      const res = await postData(
        `categories?type=sub&for=recipe&status=${true}&limit=${1000}&offset=${0}`,
        {},
        "GET"
      );

      const obj = [];
      if (res.status === 200) {
        res.data.list.forEach((ele, i) => {
          obj[i] = {
            _id: ele._id,
            label: ele.name,
            isEnabled: false,
          };
        });
      }

      setSubCategories(obj);
    };

    loadSubCategories();
  };

  const onImageChange = async (e) => {
    setLoader(true);
    if (e.target.files.length) {
      setImage(URL.createObjectURL(e.target.files[0]));
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const imgRes = await fileUpload("file-upload", formData, "POST");

      if (imgRes.status === 200) {
        setImage(imgRes?.location);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    const res = await postData(
      `recipe/${editData?._id ? "edit" : "add"}`,
      {
        title: name,
        servings,
        description,
        note,
        category_id: catid,
        sub_category_id: subcategories.filter((ele) => ele.isEnabled === true),
        ingredients: fromIngredient,
        nutritions: formNutrition,
        cook_time: cooktime,
        prep_time: preptime,
        image: image,
        total_time: totalTime,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      editData?._id ? "PUT" : "POST"
    );

    if (res.status === 200 || res.status === 201) {
      setLoader(false);
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setLoader(false);
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    const loadCategories = async () => {
      const res = await postData(
        `categories?type=main&for=recipe&status=${true}&limit=${1000}&offset=${0}`,
        {},
        "GET"
      );
      let cat = [];
      cat.push({ _id: "", label: "Select", value: "" });
      Array.isArray(res.data?.list) &&
        res.data.list.forEach((item, index) => {
          cat.push({ _id: item._id, label: item.name, value: item._id });
        });

      setCategories(cat);
    };

    const loadSubCategories = async () => {
      const res = await postData(
        `categories?type=sub&for=recipe&status=${true}&limit=${1000}&offset=${0}`,
        {}
      );

      const obj = [];
      if (res.status === 200) {
        res.data.list.map((ele, i) => {
          obj[i] = {
            _id: ele._id,
            label: ele.name,
            isEnabled: false,
          };
        });
      }

      setSubCategories(obj);
    };

    const loadIngredient = async () => {
      const res = await postData(
        `ingredient/list?status=${true}&limit=${1000}&offset=${0}`,
        {}
      );
      let ing = [];
      ing.push({ _id: "", label: "Select", value: "" });
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item) => {
          ing.push({ _id: item._id, label: item.title, value: item._id });
        });
      setIngredient(ing);
    };

    const loadNutritions = async () => {
      const res = await postData(
        `nutrition/list?status=${true}&limit=${9999}&offset=${0}`,
        {}
      );
      let nut = [];
      nut.push({ _id: "", label: "Select", value: "" });
      Array.isArray(res.data.list) &&
        res.data.list.forEach((item) => {
          nut.push({ _id: item._id, label: item.title, value: item._id });
        });

      setNutrition(nut);
    };

    loadCategories();
    loadSubCategories();
    loadIngredient();
    loadNutritions();
  }, []);

  const addIngredientFields = () => {
    setIngredientsValues([
      ...fromIngredient,
      {
        ingredient: "",
        qty: "",
      },
    ]);
  };

  const removeIngredientFields = (index) => {
    let newIngredientValues = fromIngredient.filter((e, i) => index !== i);

    setIngredientsValues(newIngredientValues);
  };

  const addNutritionFields = () => {
    setNutritionValues([
      ...formNutrition,
      {
        nutrition: "",
        qty: "",
      },
    ]);
  };
  
  const removeNutritionFields = (index) => {
    let newNutritionValues = formNutrition.filter((e, i) => index !== i);

    setNutritionValues(newNutritionValues);
  };

  const updateHandler = async (id, checked) => {
    setSubCategories(
      subcategories.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={loader}
      onSubmit={onSubmit}
    >
      <div className="relative w-full flex gap-4">
        <div className="w-2/3">
          <div className="flex items-end gap-4">
            <div className="mb-4 w-2/3">
              <Input
                label={"Recipe Name"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Recipe Name"}
                inputValue={name}
                setInput={setName}
              />
            </div>
            {categories.length > 0 && (
              <div className="mb-4 w-1/3">
                <Select
                  labelClasses={"!text-xs"}
                  xPlacement={"left"}
                  dropdownButtonClass={"!bg-white"}
                  selectedValue={catid}
                  dropdownData={categories}
                  label={"Select Category"}
                  getSelectedValue={(e) => setCatid(e.value)}
                />
              </div>
            )}
          </div>

          {subcategories.length > 0 && (
            <div className="mb-4">
              <div className="text-xs font-medium text-slate-500 mb-1">
                Sub Category
              </div>
              <div className="relative w-full">
                <DropdownCheckbox
                  data={subcategories}
                  functions={(id, event) => updateHandler(id, event)}
                />
              </div>
            </div>
          )}

          {fromIngredient?.map((item, index) => {
            return (
              <>
                <div className="flex items-end gap-4" key={item._id}>
                  <div className="w-full flex gap-4">
                    {ingredients.length > 0 && (
                      <div className="mb-4 w-3/4">
                        <Select
                          labelClasses={"!text-xs"}
                          xPlacement={"left"}
                          dropdownButtonClass={"!bg-white"}
                          selectedValue={item.ingredient}
                          dropdownData={ingredients}
                          label={"Select Ingredient"}
                          getSelectedValue={(e) =>
                            IngredientSelect(index, e.value)
                          }
                        />
                      </div>
                    )}
                    <div className="mb-4 w-1/4">
                      <Input
                        label={"Quantity"}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={"Enter Quantity"}
                        inputValue={item.qty}
                        inputName={"qty"}
                        setInput={(e) => {
                          setIngredientsValues((a) => {
                            a[index].qty = e;
                            return [...a];
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={"mb-4"}>
                    {index === 0 && (
                      <Button
                        buttonClasses={"!w-10 !h-10 !p-0 !bg-emerald-500"}
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-plus"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => addIngredientFields()}
                      />
                    )}
                    {index !== 0 && (
                      <Button
                        buttonClasses={"!w-10 !h-10 !p-0 !bg-rose-500"}
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-minus"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => removeIngredientFields(index)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}

          {formNutrition?.map((item, index) => {
            return (
              <>
                <div className="flex items-end gap-4" key={item._id}>
                  {nutritions.length > 0 && (
                    <div className="mb-4 w-3/4">
                      <Select
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        dropdownButtonClass={"!bg-white"}
                        selectedValue={item.nutrition}
                        dropdownData={nutritions}
                        label={"Select Nutritions"}
                        getSelectedValue={(e) =>
                          nutritionSelect(index, e.value)
                        }
                      />
                    </div>
                  )}
                  <div className="mb-4 w-1/4">
                    <Input
                      label={"Quantity"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter Quantity"}
                      inputValue={item.qty}
                      inputName={"qty"}
                      setInput={(e) => {
                        setNutritionValues((a) => {
                          a[index].qty = e;
                          return [...a];
                        });
                      }}
                    />
                  </div>

                  <div className={"mb-4"}>
                    {index === 0 && (
                      <Button
                        buttonClasses={"!w-10 !h-10 !p-0 !bg-emerald-500"}
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-plus"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => addNutritionFields()}
                      />
                    )}
                    {index !== 0 && (
                      <Button
                        buttonClasses={"!w-10 !h-10 !p-0 !bg-rose-500"}
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-minus"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => removeNutritionFields(index)}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}

          <div className="mb-4">
            <Input
              label={"Recipe Servings"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Recipe Servings"}
              inputValue={servings}
              setInput={setServings}
            />
          </div>
          <div className="flex items-end gap-4">
            <div className="mb-4 w-1/3">
              <Input
                label={"Cook time"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Cook time"}
                inputValue={cooktime}
                setInput={setCooktime}
              />
            </div>
            <div className="mb-4 w-1/3">
              <Input
                label={"Prep time"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Prep time"}
                inputValue={preptime}
                setInput={setPreptime}
              />
            </div>
            <div className="mb-4 w-1/3">
              <Input
                label={"Total time"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Total time"}
                inputValue={totalTime}
                setInput={setTotaltime}
              />
            </div>
          </div>

          <div className="text-sm font-medium text-slate-500 mb-1 mt-3">
            Description
          </div>
          <div className="mb-4">
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (descriptionRef.current = editor)}
              value={description}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "code",
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "code | undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help ",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={(newText) => setDescription(newText)}
            />
          </div>
        </div>
        <div className="w-1/3">
          {image !== null && (
            <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-4 mt-5">
              <Button
                buttonClasses={
                  "!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm"
                }
                buttonType={"button"}
                buttonIcon={"fa-light fa-xmark"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                functions={() => setImage(null)}
              />
              <Image
                src={image}
                alt={name}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-cover"}
              />
            </div>
          )}
          <div className="mb-4 mt-5">
            <ButtonFile
              functions={onImageChange}
              buttonLabel="Upload Image"
              buttonIconPosition=""
              buttonClasses={
                "!bg-white !text-slate-400 border border-slate-200"
              }
            />
          </div>

          <div className="mb-4">
            <div className="text-xs font-medium text-slate-500 mb-1">Note</div>
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (noteRef.current = editor)}
              value={note}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "code",
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "code | undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help ",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onEditorChange={(newText) => setNote(newText)}
            />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditRecipeModal;
