import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditSubCategoryModal from "../components/modals/AddEditSubCategoryModal";
import SearchCategories from "../components/elements/search/SearchCategories";

const SubCategories = () => {
  const user = useSelector((state) => state.auth.user);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [searchCategory, setSearchCategory] = useState([]);
  const [categoriesId, setCategoriesId] = useState("");

  useEffect(() => {
    const searchList = async () => {
      const res = await postData(
        `categories?type=1&for=recipe&limit=9999`,
        {}
      );

      if (res.status === 200) {
        const obj = [];
        obj[0] = {
          _id: "",
          label: "Categories",
          value: "",
        };
        res.docs.forEach((ele, i) => {
          obj[i + 1] = {
            _id: ele._id,
            label: ele.name,
          };
        });
        setSearchCategory(obj);
      }
    };

    searchList();
  }, []);

  useEffect(() => {
    if (user?.email) {
      const loadList = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }
        const res = await postData(`categories?keyword=${keyword}&parent_id=${categoriesId}&type=2&for=recipe&limit=${limit}&offset=${offset}`, {
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.docs,
            loading: false,
            count: res.totalDocs,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadList();
    }
  }, [user, keyword, limit, offset, sortField, sortType, categoriesId]);

  const statusChange = async (id) => {
    const res = await postData("crud/statusChange", {
      table_name: "category",
      id: [id],
      column_name: "status",
      column_value: !list.data.find((item) => item._id === id).status || false,
    },"POST");

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      action: true,
    },
  ];

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: statusChange,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `category/delete/${item._id }`,
      standout:true
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData("crud/ordering", {
      table_name: "category",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    },"POST");

    if (res.status !== 200) {
      toastr.error(res.message);
    }
  };

  const handleSelect = (value) => {
    setCategoriesId(value);
  };

  return (
    <>
      <ListLayout
        title="Sub Categories"
        addName="Add Sub Category"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
        searchCategory={
          <SearchCategories data={searchCategory} handleSelect={handleSelect} />
        }
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
        />
      </ListLayout>
      <AddEditSubCategoryModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0
            ? "Edit Sub Category"
            : "Add Sub Category"
        }
        setList={setList}
        editData={editData}
        list={list.data}
        setEditData={setEditData}
      />
    </>
  );
};

export default SubCategories;
