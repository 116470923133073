import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditUserModal from "../components/modals/AddEditUserModal";

const User = () => {
  setTitle("zuso | users");
  const user = useSelector((state) => state.auth.user);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (user?.email) {
      const loadList = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }
        const res = await postData(
          `users?keyword=${keyword}&type=user&limit=${limit}&offset=${offset}&role=6399a6b3302d0b2a866e0a58`,
          {},
          "GET"
        );
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.docs,
            loading: false,
            count: res.totalDocs,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadList();
    }
  }, [user?.email, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Image",
      name: "image",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      _id: 1,
      width: 15,
      title: "  Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 2,
      width: 15,
      title: " Email",
      name: "email",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: " Phone",
      name: "phone",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "Created At",
      name: "createdAt",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      title: "Action",
      name: "action",
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData(
      "users/change-status",
      {
        ids: [id],
        status: list.data.find((item) => item._id === id).status === 1 ? 2 : 1,
      },
      "PATCH"
    );

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status:
                  list.data.find((item) => item._id === id).status === 1
                    ? 2
                    : 1,
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };


  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: "Preview",
      icon: "fa-eye",
      type: "link",
      link: "/user/preview",
    },
    {
      name: item.status===1 ? "Inactive" : "Active",
      icon: item.status===1 ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: `users/${item._id}`,
      standout:true
    },
  ];

  return (
    <>
      <ListLayout
        title="Users"
        addName="Add User"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        totalCount={list.count}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
        />
      </ListLayout>

      <AddEditUserModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit User" : "Add User"}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default User;
