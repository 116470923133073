import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import DropdownCheckbox from "../form/DropdownCheckbox";

const AddEditClassModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  setList = () => {},
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [recipes, setRecipe] = useState([]);

  const loadRecipeList = async () => {
    const res = await postData(
      `recipe/list?status=${true}&limit=${1000}&offset=${0}`,
      {},
      "GET"
    );
    if (res.status === 200) {
      let recipe = [];
      Array.isArray(res.data.list) &&
        res.data.list.forEach((it) => {
          recipe.push({ label: it.title, _id: it._id, isEnabled: false });
        });

      setRecipe(recipe);
    }
  };

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setDescription(editData.description);
      
    }
  }, [editData]);

  useEffect(()=>{
    loadRecipeList();
  },[])

  useEffect(() => {
    if (editData._id) {
      const loadRecipeList = async () => {
        const res = await postData(
          `recipe/list?status=${true}&limit=${1000}&offset=${0}`,
          {},
          "GET"
        );
        if (res.status === 200) {
          let recipe = [];
          Array.isArray(res.data.list) &&
            res.data.list.forEach((it, i) => {
              recipe.push({ label: it.title, _id: it._id, isEnabled: false });
            });

          const detailres = await postData(
            `class/details?id=${editData?._id}`,
            {},
            "GET"
          );
          if (detailres.status === 200) {
            setRecipe(
              Array.isArray(recipe) &&
                recipe.map((it) => ({
                  ...it,
                  isEnabled:
                    detailres.data &&
                    Array.isArray(detailres.data.recipes) &&
                    detailres.data.recipes
                      .map((item) => item._id)
                      .includes(it._id),
                }))
            );
          }
        }
      };

      loadRecipeList();
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("");
    setDisabled(false);
    setEditData({});
    loadRecipeList();
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(
      `class/${editData?._id ? "edit" : "add"}`,
      {
        id: editData?._id || null,
        name: name,
        description,
        recipes: recipes.filter((item) => item.isEnabled === true),
      },
      editData?._id ? "PUT" : "POST"
    );

    if (res.status === 200 || res.status === 201) {
      setName("");
      setDescription("");
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };
  const updateHandler = async (id, checked) => {
    setRecipe(
      recipes.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize="m"
    >
      <div className="relative w-full">
        <div className="mb-4">
          <Input
            label={"Class Name"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Class Name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-4">
          <Input
            label={"Class Description"}
            labelClasses={"!text-xs"}
            inputType={"text"}
            inputPlaceholder={"Enter Description"}
            inputValue={description}
            setInput={setDescription}
          />
        </div>

        <div className="mb-4">
          <div className="text-xs font-medium text-slate-500 mb-1">Recipes</div>
          <div className="relative w-full">
            <DropdownCheckbox
              data={recipes}
              recipes={recipes}
              label={"Recipes"}
              functions={(id, event) => updateHandler(id, event)}
            />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditClassModal;
