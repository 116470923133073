import React , { useState, useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import "../../assets/flatpicker-theme.css";

const InputDate = ({label, labelClasses, placeholder,  value, onChange}) => {
  const fp = useRef();
  const [fpDate,setFpdate]=useState("");

  return (
    <>
      {label && 
        <div className={"text-sm font-medium text-slate-500 mb-1 " + (labelClasses)}>{label}</div>
      }
      <div className="relative flex">
        <Flatpickr 
          data-enable-time 
          ref={fp}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full h-10 rounded bg-white border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400  transition-all duration-200"
        />
      </div>
    </>
  );
};

export default InputDate;