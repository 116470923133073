import React from 'react';
import { useState } from "react";
import Button from "../components/form/Button";

const UserPreview = () => {

  const tabData = [
    {
      _id:1,
      title:"Favourite",
      icon:"fa-regular fa-heart",
    },
    {
      _id:2,
      title:"Cooking",
      icon:"fa-regular fa-grill-hot",
    },
    {
      _id:3,
      title:"Meal Plans",
      icon:"fa-regular fa-plate-utensils",
    }
  ]

  const [tabActive, setTabActive] = useState(tabData[0]);
  console.log(tabActive._id);
  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">User Preview</div>
      </div>
      <div className="relative">
        <div className="flex gap-8 border-b border-slate-300">
          {tabData.map((item, index) => (
            <Button
              key={item._id}
              buttonClasses={"!rounded-none !bg-transparent !border-b-[3px] -mb-[1px] !px-0 " + ((item._id === tabActive._id)?"!border-primary-500 !text-primary-500":"!border-transparent !text-slate-600")}
              buttonType={"button"}
              buttonIcon={item.icon}
              buttonIconPosition={"left"}
              buttonLabel={item.title}
              buttonLabelClasses={""}
              buttonHasLink={false}
              functions={() => setTabActive(tabData[index])}
            />
          ))}
        </div>
        <div className="relative">
          {tabActive._id === 1 &&
          <div className="">0</div>
          }
          {tabActive._id === 2 &&
          <div className="">1</div>
          }
          {tabActive._id === 3 &&
          <div className="">2</div>
          }
        </div>
        
      </div>
    </>
  );
};

export default UserPreview;