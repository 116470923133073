import React from 'react';
const NoDataFound = ({ label}) => {
  return (
    <>
      <div className="flex items-center justify-center flex-col gap-2 h-24">
        <div className="text-5xl text-primary-500"><i className="fa-regular fa-fw fa-circle-exclamation"></i></div>
        <div className="text-base text-slate-400">{label}</div>
      </div>
    </>
  )
}
export default NoDataFound;